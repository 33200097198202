.header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 10px;
    background-color: #222;
    color: #fff;
}

.header-logo {
    margin: 0;
}

.header-logout {
    display: flex;
    justify-content: center;
    background-color: #ccc;
    align-items: center;
    border-radius: 50%;
    padding: 7px 7px;
    font-size: 22px;
    border: none;
    text-decoration: none;
    cursor: pointer;
}

.header-logout:hover {
    opacity: 0.7;
}

.dashboard-root {
    display: flex;
    flex-direction: column;
    height: 100%;
}

.main {
    flex: 1;
    display: flex;
    flex-direction: column;
}

.dashboard-content {
    display: flex;
    justify-content: center;
    padding: 20px;
}

.dashboard-link {
    border: 1px #666;
    border-radius: 10px;
    background-color: #ccc;
    padding: 20px;
    margin: 0 10px;
    text-decoration: none;
    color: #000;
    cursor: pointer;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.dashboard-link:hover {
    opacity: 0.7;
}

.dashboard-link span {
    margin-top: 15px;
    font-weight: bold;
}

.main-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    background-color: #ccc;
    padding: 10px;
}

.main-header * {
    margin: 0;
}

.header-link {
    text-decoration: none;
    font-size: 18px;
    padding: 5px;
    border-radius: 50%;
    color: #fff;
    background-color: #999;
    display: flex;
    justify-content: center;
    align-items: center;
    align-self: center;
    cursor: pointer;
    border: 0;
}

.header-link:hover {
    opacity: 0.7;
}

.main-header-actions {
    display: flex;
    align-items: center;
}

.main-header-actions .header-link {
    margin-left: 10px;
}

.main-content {
    flex: 1;
    margin: 0;
    padding: 0;
}

ul.main-content {
    list-style: none;
}

ul.main-content li {
    padding: 10px;
    border-bottom: 1px solid #ccc;
    display: grid;
    grid-template-columns: 2fr 1fr 1fr .3fr .3fr;
    justify-items: flex-start;
    align-items: center;
    color: #333;
}

ul.main-content .main-content-header {
    font-weight: bold;
}

ul.main-content .main-content-link {
    text-decoration: none;
}

ul.main-content .main-content-link:hover li {
    background-color: #ccc;
}

.modal {
    display: flex;
    flex-direction: column;
    padding: 5px;
    font-size: 14px;
}

.modal-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 10px;
}

.close-button {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 5px;
    border-radius: 50%;
}

.close-button:hover {
    background-color: #ccc;
}

.modal-buttons {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-top: 15px;
}

.cancel-button {
    border: 1px solid #fff;
    background-color: #fff;
    border-radius: 3px;
    padding: 5px 15px;
    cursor: pointer;
}

.cancel-button:hover {
    border: 1px solid #ccc;
}

.create-button {
    border: 1px solid #ccc;
    background-color: #222;
    color: #fff;
    padding: 5px 15px;
    cursor: pointer;
}

.create-button:hover {
    opacity: 0.7;
}

.modal-input-group {
    margin: 8px 0;
    display: flex;
    flex-direction: column;
    align-items: stretch;
}

.modal-input-group label {
    margin-bottom: 5px;
}

.main-single-content {
    width: 80%;
    align-self: center;
    display: flex;
    flex-direction: column;
    padding-top: 20px;
}

.exam-basic-info {
    display: flex;
    justify-content: space-between;
    align-items: center;
    font-size: 18px;
}

.exam-basic-info span {
    color: #666;
    font-weight: bold;
}

.exam-question-list,
.question-option-list {
    margin: 20px 0 0;
    padding: 0;
    align-self: stretch;
    list-style: none;
}

.exam-question-list li,
.question-option-list li {
    margin-bottom: 5px;
}

.question-option-list li {
    display: flex;
    justify-content: flex-start;
    align-items: center;
}

.exam-question-list li {
    width: 100%;
    display: grid;
    grid-auto-flow: row;
    grid-template-columns: 1fr auto;
    align-items: center;
}

.exam-question-list li select {
    width: 100%;
    overflow: hidden !important;
    white-space: nowrap;
    text-overflow: ellipsis;
}

.exam-question-list li .header-link {
    flex: 1;
    margin-left: 10px;
}

.exam-question-list li select option {
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
}

.main-content-header .list-actions {
    grid-column: span 2;
}
